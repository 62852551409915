import React from 'react';
import PropTypes from 'prop-types';

import '../style/content-section.scss';

export const CLASS_NAME = 'content-section';

export class ContentSection extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(['normal', 'hidden']),
  };

  static defaultProps = {
    children: null,
    type: 'normal',
  };

  render() {
    const { children, type } = this.props;
    const className = `${CLASS_NAME} ${CLASS_NAME}--${type}`;

    return (
      <section className={className}>
        {children}
      </section>
    );
  }
}
