import React from 'react';
import PropTypes from 'prop-types';

import '../style/selector.scss';

export const CLASS_NAME = 'selector';

export class Selector extends React.PureComponent {
  static propTypes = {
    options: PropTypes.node,
    onChange: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    value: null,
    options: [],
    onChange: () => {},
  };

  handleChange = ({ target }) => {
    const { onChange } = this.props;
    const { value } = target;

    onChange(value);
  };

  renderOptions() {
    const { options } = this.props;

    return options.map((option, i) => {
      const key = `option-${option}-${i}`;

      return (
        <option key={key} value={option}>
          {option}
        </option>
      );
    });
  }

  render() {
    const { value } = this.props;

    return (
      <select
        value={value}
        className={CLASS_NAME}
        onChange={this.handleChange}
        tabIndex={0}
      >
        {this.renderOptions()}
      </select>
    );
  }
}
