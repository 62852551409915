import React from 'react';
import PropTypes from 'prop-types';

import '../style/content-subtext.scss';

export const CLASS_NAME = 'content-subtext';

export class ContentSubtext extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const { children } = this.props;
    return (
      <p className={CLASS_NAME}>
        {children}
      </p>
    );
  }
}
