import React from 'react';
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from 'react-router-dom';

import { ScrollToTopRoute } from './routes/route-scroll-to-top';
import { HomeRoute } from './routes/route-home';

function MissingFromRedirect() {
  const { transcodeFrom } = useParams();
  return <Navigate to={`/${transcodeFrom}/deva`} replace />;
}

function HomeWithParams() {
  const params = useParams();
  return <HomeRoute params={params} />;
}

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <ScrollToTopRoute>
          <Routes>
            <Route path="/" element={<Navigate to="/hk/deva/" replace />} />
            <Route path="/:transcodeFrom" element={<MissingFromRedirect />} />
            <Route path=":transcodeFrom/:transcodeTo/:searchString?" element={<HomeWithParams />} />
          </Routes>
        </ScrollToTopRoute>
      </Router>
    );
  }
}
