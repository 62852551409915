import React from 'react';
import HtmlToReact from 'html-to-react';

const HtmlToReactParser = HtmlToReact.Parser;
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const htmlToReactParser = new HtmlToReactParser();

// Define behaviours

const validTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'b', 'i', 'em', 'u', 'span', 'a'];
const validDictionaryTags = ['bio', 'etym', 'pcol', 'i', 'shortlong', 's', 's1', 'ab', 'lex', 'ls', 'L', 'pc', 'info', 'hom', 'srs', 'pb', 'div', 'lang', 'bot', 'ns'];

const isValidNode = () => { return true; };

const isTextNode = ({ type = null }) => {
  return type === 'text';
};

const isValidTagName = ({ type = null, name = null }) => {
  return type === 'tag' && name && validTags.indexOf(name) > -1;
};

const isValidDictionaryTagName = ({ type = null, name = null }) => {
  return type === 'tag' && name && validDictionaryTags.indexOf(name) > -1;
};

// Export renderer

export default function renderHtml(htmlString) {
  const html = `${htmlString}`;

  const processingInstructions = [{
    replaceChildren: false,
    shouldProcessNode: (node) => {
      return (isValidTagName(node) || isTextNode(node));
    },
    processNode: processNodeDefinitions.processDefaultNode,
  }];

  return htmlToReactParser.parseWithInstructions(
    html,
    isValidNode,
    processingInstructions,
  );
}

export function renderDictionaryElement(xmlString, processor) {
  const html = `${xmlString}`;

  const processingInstructions = [{
    replaceChildren: false,
    shouldProcessNode: (node) => {
      return isTextNode(node);
    },
    processNode: processNodeDefinitions.processDefaultNode,
  }, {
    replaceChildren: false,
    shouldProcessNode: (node) => {
      const valid = isValidDictionaryTagName(node);

      if (!valid) {
        console.log('invalid', { node });
      }

      return valid;
    },
    processNode: (node, children) => {
      return processor(node, children);
    },
  }];

  return htmlToReactParser.parseWithInstructions(
    html,
    isValidNode,
    processingInstructions,
  );
}
