import queryString from 'query-string';

export class URLTool {
  constructor(location, navigate) {
    this.location = location;
    this.navigate = navigate;
  }

  update(location, navigate) {
    this.location = location;
    this.navigate = navigate;
  }

  getQuery() {
    const { location } = this;
    const query = queryString.parse(location.search);

    return query;
  }

  buildSearchString(query) {
    const search = query ? `?${queryString.stringify(query)}` : '';
    return search;
  }

  updateQuery(obj) {
    const { search: oldSearch } = this.location;
    const searchParsed = queryString.parse(oldSearch);

    Object.keys(obj).forEach((key) => {
      const entry = obj[key];

      Object.assign(searchParsed, {
        [key]: !entry ? undefined : entry,
      });
    });

    const searchString = `${queryString.stringify(searchParsed)}`;
    const search = searchString ? `?${queryString.stringify(searchParsed)}` : '';

    this.navigate({
      pathname: this.location.pathname,
      search,
    });
  }

  replacePathElement(from, to) {
    const {
      search,
      pathname: oldLocation,
    } = this.location;

    const newLocation = oldLocation.replace(new RegExp(from, 'ig'), to);

    if (newLocation !== oldLocation) {
      this.navigate({
        pathname: newLocation,
        search,
      });
    }
  }

  replaceTo(path) {
    const { pathname: currentPathname } = this.location;

    if (typeof path === 'string') {
      if (path !== currentPathname) {
        this.navigate({ pathname: path }, { replace: true });
      }
    } else {
      const { pathname, search } = path;

      if (currentPathname !== pathname) {
        this.navigate({ pathname, search }, { replace: true });
      }
    }
  }

  navigateTo(path) {
    const { pathname: currentPathname } = this.location;

    if (typeof path === 'string') {
      if (path !== currentPathname) {
        this.navigate({ pathname: path });
      }
    } else {
      const { pathname, search } = path;

      if (currentPathname !== pathname) {
        this.navigate({ pathname, search });
      }
    }
  }

  leaveTo(url) {
    window.location.href = url;
  }

  getCurrentPath() {
    const { pathname, search } = this.location;
    const path = search ? `${pathname}${search}` : pathname;

    return path;
  }
}

export const urlTool = new URLTool();
export default urlTool;
